<template>
  <div>
    <BatchList :batchType="batchType" @export="exportList" ref="first" active='first'>
      <template #topRight>
        <!-- <el-button size="mini" style="width:100px;margin:10px;" icon="el-icon-plus" type="primary" @click="addSingleShow=true">单个发放</el-button> -->
        <el-button size="mini" style="width:100px;margin:0px 10px;" icon="el-icon-plus" type="primary" @click="addBatchShow=true">批量发放</el-button>
      </template>
      <template #table="row">
        <i class="el-icon-view btn-hover" @click="editDetail(row.scope,1)">查看</i>
        <i class="el-icon-circle-check btn-hover" @click="editDetail(row.scope,3)" v-if="[0,3].indexOf(row.scope.auditStatus) != -1">提交</i>
        <i class="el-icon-edit btn-hover" @click="editDetail(row.scope,2)" v-if="[0,3].indexOf(row.scope.auditStatus) != -1">编辑</i>
      </template>
    </BatchList>
    <AddBatch v-if="addBatchShow" :show.sync="addBatchShow" :batchType="batchType" @submit="submitBatch"></AddBatch>
    <AddSingle v-if="addSingleShow" :show.sync="addSingleShow" :batchType="batchType" @submit="submitSingle"></AddSingle>
    <el-dialog :destroy-on-close="true" :title="isEditDialogTitle[Number(editType)-1]" :visible.sync="isEditDialog" :lock-scroll="false" :close-on-click-modal="false" width="740px">
      <EditBatch v-if="isEditDialog" :isAddDialog.sync="isEditDialog" :editId.sync="editId" :editType.sync="editType"></EditBatch>
    </el-dialog>
  </div>
</template>
<script>

import BatchList from '@/components/BatchList.vue'
import AddBatch from '@/components/AddBatch.vue'
import AddSingle from '@/components/AddSingle.vue'
import EditBatch from './components/editBatch.vue'
import { exportCommon } from "@/utils/utils";
import { auditBatchImportBatch, singleAddBatch, confirmImport, singleCancelCode } from '@/api/codes';
export default {
  name: "codes",
  components: {
    BatchList,
    AddBatch,
    AddSingle,
    EditBatch
  },
  data() {
    return {
      isAddDialog: false,
      active: 'first',
      batchType: '',
      addBatchShow: false,
      addSingleShow: false,
      isEditDialogTitle: ['查看批次详情', '编辑批次详情', '提交批次详情'],
      isEditDialog: false,
      editId: '',
      editType: '',            // 编辑状态 1是详情，2是编辑,''是添加
    }
  },
  created() {
    this.onLoad()
  },
  watch: {
    isEditDialog() {
      this.$refs.first.getList()
    },

  },
  methods: {
    // type == 1 查看详情 type == 2 编辑
    editDetail(item, type) {
      console.log(item, type)
      this.editType = type;
      this.editId = item;
      this.isEditDialog = true;
    },
    onLoad() {
      this.active = 'first'
      this.batchType = this.$route.meta.batchType
      console.log('this.batchType = ', this.$route, this.batchType)
    },
    exportList(row) {
      console.log(row)
      let param = {
        batchId: row.batchId,
        batchType: row.batchType
      }
      exportCommon("/cancelCode/exportExcel", param);
    },
    submitBatch(list, title, auditCode, fileUrl) {//批量发放
      let success = (response) => {
        this.addBatchShow = false
        this.$refs.first.getList()
        this.$message({
          title: "错误",
          type: response.code == 200 ? "success" : "error",
          message: response.msg || response.error_msg || response.message,
          duration: 1500
        });
      }
      console.log(1111, title)
      if (this.batchType == 0) {//现油核销码
        confirmImport(title, auditCode, list).then(response => {
          success(response)
        })
        return
      }
      auditBatchImportBatch({ codeType: this.batchType, title, auditCode, list, fileUrl }).then(response => {
        success(response)
      });
    },
    submitSingle(form) {//单个发放
      let success = (response) => {
        if (response.code == 200) {
          this.addSingleShow = false
          this.$refs.first.getList()
        }
        this.$message({
          type: response.code == 200 ? "success" : "error",
          message: response.msg || response.error_msg || response.message,
          duration: 1500
        });
      }
      if (this.batchType == 0) {//现油核销码
        singleCancelCode(form).then(response => {
          success(response)
        });
        return
      }
      singleAddBatch(form).then(response => {
        success(response)
      });
    }
  }
}
</script>