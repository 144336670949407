<template>
  <div style="width:100%;">
    <el-row style="text-align:left">
      <el-col :span="12">标题 : {{editId.title}}</el-col>
      <el-col :span="12">审批单号 : {{editId.auditCode}}</el-col>
      <el-col :span="12">现油金额 : {{editId.amount}}</el-col>
      <!-- <el-col :span="12">发放金额 : {{editId.integralGrantAmount}}</el-col>
      <el-col :span="12">撤回金额 : {{editId.integralWithdrawAmount}}</el-col> -->
      <el-col :span="12">总人数 : {{editId.clientCount}}</el-col>
      <el-col :span="12" v-show="editId.fileUrl">下载文件 :
        <a :href="editId.fileUrl" style="margin:0 10px">
          <el-button type="text" icon="el-icon-download">下载</el-button>
        </a>
      </el-col>
    </el-row>
    <div class="list-box">
      <el-table border stripe :data="list">
        <el-table-column type="index" align="center" label="序号" width="90"></el-table-column>
        <el-table-column prop="usedName" align="center" label="客户名称"></el-table-column>
        <el-table-column prop="exchangeAmount" align="center" label="面额"></el-table-column>
        <el-table-column prop="usedPhone" align="center" label="手机号"></el-table-column>
        <el-table-column width="120" label="操作" v-if="editShow">
          <template slot-scope="scope">
            <div>
              <!-- <el-popover placement="right" width="400" trigger="click" v-model="scope.row.isSelected">
                <EditBatchItem v-if="scope.row.isSelected" :isSelected.sync="scope.row.isSelected" :editItem.sync="scope.row" @changeListItem="changeListItem" />
                <i slot="reference" class="el-icon-edit btn-hover">修改</i>
              </el-popover> -->
              <i slot="reference" class="el-icon-delect btn-hover" @click="delectItem(scope.row.id)">删除</i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize" :current-page="pageNum" :total="total">
        </el-pagination>
      </div>
    </div>
    <div>
      <el-button type="primary" v-debounce="submitApproval" v-if="editType == 3">确认提交</el-button>
      <el-button type="info" @click="dialogClose">关闭窗口</el-button>
    </div>
  </div>
</template>
<script>
import { getCookies } from '@/utils/utils'
import { getBatchCustomerList, deleteAutonym, submitApproval } from '@/api/codes';
import EditBatchItem from './editBatchItem.vue'
export default {
  props: ['isAddDialog', 'editId', 'editType'],
  components: {
    EditBatchItem,
  },
  data() {
    return {
      list: [],
      total: 0,//分页总页数
      pageNum: 1,//分页页数
      pageSize: 10,//每页显示多少
    }
  },
  computed: {
    editShow() {
      return [2].indexOf(this.editType) != -1 || !this.editType
    }
  },
  created() {
    console.log(this.editId)
    this.editType && this.editId && this.getDateil();
  },
  methods: {
    changeListItem(msg) {
      let list = JSON.parse(JSON.stringify(this.list));
      this.$set(this.list, list.findIndex(item => item.id == msg.id), msg)
    },
    dialogClose() {
      this.active = 0;
      this.$emit('update:isAddDialog', false)
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDateil();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getDateil();
    },
    submitApproval(id) {
      let that = this;
      if (this.list.length < 1 || this.list.length == 0) {
        this.$message({
          title: '错误',
          type: 'error',
          message: '批次客户为0，不可提交',
          duration: 1500
        });
        return false;
      }
      submitApproval({ batchId: that.editId.batchId, submitBy: getCookies('sysId') })
        .then((response) => {
          if (response.code == 200) {
            that.dialogClose();
          }
          that.$message({
            title: '错误',
            type: response.code == 200 ? 'success' : 'error',
            message: response.msg || response.error_msg || response.message,
            duration: 1500
          });
        })
    },
    delectItem(id) {
      let that = this;
      deleteAutonym(id)
        .then((response) => {
          if (response.code == 200) {
            that.list = that.list.filter(item => item.id !== id);
          }

        })
    },
    getDateil() {
      let that = this;
      let param = {
        isPage: 1,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
        batchType: 0,
        batchId: this.editId.batchId
      };

      getBatchCustomerList(param)
        .then((response) => {
          if (response.code == 200) {
            that.total = response.data.total;
            let list = response.data.records;
            that.list = list.map(v => {
              return { ...v, isSelected: false }
            })
          }

        })
    },
  }
}
</script>
<style scoped>
.list-box {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  max-height: 600px;
  margin: 0px auto 20px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid #b4b4b4;
}
.el-col-12 {
  margin: 10px 0;
}
.el-col-12:nth-child(2n) {
  text-align: right;
}
</style>